<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- new product section -->
    <section id="about_us" class="py-10 bg-gray-50">
      <div class="container">
        <div class="md:max-w-xl lg:max-w-2xl md:mx-auto ">
        <div class="tracking-wide text-center text-gray-700 md:text-left">
          <p class="pb-5 text-2xl font-semibold leading-tight lg:text-3xl">
            Shopping for Curtains & Blinds can feel stressed and confusing.
          </p>
          <p class="text-2xl font-semibold leading-tight lg:text-3xl">
            At <span class="font-semibold text-gold-1">Curtain+</span>, we make
            it fun and easy for you
          </p>
        </div>
        <div
          class="mt-5 space-y-3 text-sm leading-tight text-center text-gray-600 md:text-base md:text-left"
        >
          <p>
            Curtains & Blinds consideration are generally at the last but
            important stage of a home decor journey
          </p>
          <p>
            We can help reduce your stress and make it simple to craft a space
            that feel like yours, so that you can enjoy your new home sooner!
          </p>
          <p>
            At Curtain+, we curate local and overseas partners to offer top
            quality curtains & blinds, customised to your unique space and
            style.
          </p>
          <p>
            Each and every Curtain+ product are tailor-made for you in just the
            way you like it. They are generally delivered speedily in 10-14
            working days.
          </p>
        </div>
      </div>
      </div>
    </section>

    <!-- product details -->
    <section id="our_services" class="py-10">
      <div class="container">
        <div class="text-center">
          <p class="text-2xl font-semibold leading-tight tracking-wide text-gray-700 lg:text-3xl">
            Curtains & Blinds for the perfect fit.
          </p>
        </div>

        <div class="mt-8">
          <div v-for="(item, i) in perfect_fit" :key="i" class="pb-6 md:pb-10 md:flex md:items-center">
            <div class="pb-5 md:w-1/2" :class="item.order">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class=""
              />
            </div>
            <div class="text-center md:w-1/2 md:px-4 md:text-left">
              <p class="text-xl font-semibold xl:text-2xl text-gold-1">{{ item.alt }}</p>
              <p class="pt-4 text-sm leading-tight text-gray-600 lg:text-base">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- why choose us -->
    <section id="why_choose_us" class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center md:max-w-xl md:mx-auto">
          <p class="text-2xl font-semibold leading-tight tracking-wide text-gray-700 lg:text-3xl">
            Why Choose Us
          </p>
          <p class="pt-4 font-semibold leading-tight lg:text-lg xl:text-xl text-gold-1">
            Enjoy a customised curtains & blinds service experience
          </p>
          <div class="pt-6 space-y-3 text-sm leading-tight text-gray-600 lg:text-base">
            <p>
              We understand how frustrating it is to deal with an annoying
              salesman or a shady quote with hidden costs.
            </p>
            <p>
              Home decor should be an enjoyable process. We have helped hundreds
              of homes to look their best. Put your mind at ease with us.
            </p>
          </div>
        </div>

        <div class="mt-8 lg:mt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in choose_us" :key="i" class="pb-6 md:w-1/4">
            <img
              :src="'/images/' + item.image + '.png'"
              :alt="item.alt"
              class="w-12 mx-auto"
            />
            <div class="pt-4 text-sm font-semibold text-center text-gray-700">
              <p>{{ item.alt }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- sub banner -->
    <section
      class="py-20 bg-center bg-no-repeat bg-cover md:py-28"
      style="background-image: url('/images/bg-sub.jpg')"
    >
      <div class="container">
        <div class="text-white md:w-2/3">
          <p class="w-32 py-1 mb-3 text-lg text-center text-white bg-red-700">
            Curtain+
          </p>
          <h1 class="text-3xl font-bold tracking-wide md:text-4xl">
            Prioritizing <span class="text-yellow-400">Workmanship</span> and
            Quality Products
          </h1>
          <p class="pt-5 text-sm tracking-wide lg:text-base">
            Transform your home with gorgeous tailor-made curtains & blinds.
          </p>
        </div>
      </div>
    </section>

    <Testimonials />

    <!-- gallery -->
    <section class="py-10">
      <div class="container">
        <div class="text-center">
          <p class="text-2xl font-semibold leading-tight tracking-wide text-gray-700 lg:text-3xl">
            Be Inspired by Our Real Homes
          </p>
          <p class="pt-4 font-semibold leading-tight lg:text-lg xl:text-xl text-gold-1">See how clients like you styled their beautiful homes with our curtains & blinds</p>
        </div>

        <div class="mt-6">
          <div v-for="(item, i) in gallery" :key="i" class="mb-6">
            <p class="w-full pb-3 text-lg font-semibold text-center text-gold-1">
              {{ item.title }}
            </p>
            <div class="flex flex-wrap">
              <div
                v-for="image in item.image"
                :key="image"
                class="w-1/2 p-1 lg:w-1/4"
              >
                <div class="overflow-hidden">
                  <img
                    :src="'/images/' + image + '.jpg'"
                    alt=""
                    class="transition duration-300 transform md:w-full hover:scale-120 hover:shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- footer -->
    <section
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div id="enquiry" class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-yellow-500 rounded-md bg-opacity-60">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo-white.png" alt="" class="mx-auto w-60" />
          <div class="text-center text-white pt-7">
            <div class="pb-5">
              
              <h1 class="text-lg font-normal leading-tight md:text-xl">
                56, Jalan Puteri 2/2, Bandar Puteri, 47100 Puchong, Selangor
              </h1>
              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-blue-1"
                >
                  <a href="https://wa.me/60124394327" class="">012-439 4327</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-blue-1"
                >
                  <a href="tel:+60124394327">012-439 4327</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Operating Hours</p>
                <p>Monday – Friday: 10AM – 5PM</p>
                <!-- <p>(Factory visit by appointment only)</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <p class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import carousel from "vue-owl-carousel";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    Testimonials,
    EnquiryForm,
    carousel,
    WaBtn,
  },
  data() {
    return {
      gallery: [
        {
          title: "Curtains",
          image: ["gallery-1", "gallery-2", "gallery-3", "gallery-4"],
        },
        {
          title: "Blinds",
          image: ["gallery-7", "gallery-8", "gallery-9", "gallery-6"],
        },
      ],
      // night_curtain: [
      //   {
      //     image: "product-1",
      //     alt: "Night Curtains",
      //     p: "Night Curtain offers more privacy and gives a full, professional appearance. They greatly reduce the light and heat coming into your room. With a wide range of solid colour options, you will be sure to find a colour that suits your decor style. There are two broad categories: Dim-out curtains offer 80-95% light blockage while Blackout curtain offers 100% light blockage for complete privacy. Night curtains are often layered with Day curtains for a contemporary, sophisticated look along with flexible light control.",
      //   },
      //   {
      //     image: "product-2",
      //     alt: "Day Curtains",
      //     p: "Day Curtains are made of thin, soft, sheer fabrics. They gently filter daylight to come through in style and yet enhance privacy. Day curtains are typically used together with Night curtains to provide flexible light control, as well as to achieve a timless layered window look.",
      //   },
      //   {
      //     image: "product-3",
      //     alt: "Semi-Sheer Curtains",
      //     p: "Semi-Sheer Curtains are in-between the typical Day and Night curtains. They are thicker than the usual day curtains and offers more privacy. However, they do not block as much light as the usual night curtains. Semi-sheer curtains allow natural light to fill up your living space and give an airy feel. They are great for living rooms and dining areas or rooms with no need for absolute privacy.",
      //   },
      // ],
      // curtain_fabric: [
      //   {
      //     image: "fabric-2",
      //     alt: "Designer Dim-out Series",
      //     // sublist: ["Blocks up to 90% sunlight", "Anti-dust", "Easy wash"],
      //     p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla gravida, nisl et dignissim pharetra, purus nibh luctus sapien, et venenatis ante ligula nec massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      //   },
      //   {
      //     image: "fabric-3",
      //     alt: "Standard Dim-out Series",
      //     // sublist: ["Blocks 100% sunlight", "Anti-dust", "Dry clean"],
      //     p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla gravida, nisl et dignissim pharetra, purus nibh luctus sapien, et venenatis ante ligula nec massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      //   },
      //   {
      //     image: "fabric-1",
      //     alt: "Basic Dim-out Series",
      //     // sublist: [
      //     //   "Blocks up to 30% sunlight",
      //     //   "Aesthetics & Privacy",
      //     //   "Easy wash",
      //     // ],
      //     p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla gravida, nisl et dignissim pharetra, purus nibh luctus sapien, et venenatis ante ligula nec massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      //   },
      //   {
      //     image: "fabric-1",
      //     alt: "Premium Dim-out Collection",
      //     // sublist: [
      //     //   "Blocks up to 30% sunlight",
      //     //   "Aesthetics & Privacy",
      //     //   "Easy wash",
      //     // ],
      //     p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla gravida, nisl et dignissim pharetra, purus nibh luctus sapien, et venenatis ante ligula nec massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      //   },
      // ],
      // roller_blinds: [
      //   {
      //     image: "blind-1",
      //     alt: "Roller Blinds",
      //     p: [
      //       "The most economical and versatile blinds. Suitable for all areas, indoors and outdoors.",
      //     ],
      //   },
      //   {
      //     image: "blind-2",
      //     alt: "Korean Rainbow Combi Blinds",
      //     p: [
      //       "Popular type of combined day & night zebra blind. Greater flexibility over light & privacy.",
      //     ],
      //   },
      //   {
      //     image: "blind-3",
      //     alt: "Venetian Blinds",
      //     p: [
      //       "Suitable for Scandinavian or Industrial design concepts. Wide range of colours. Enjoy light, air and privacy at the same time.",
      //     ],
      //   },
      //   {
      //     image: "blind-3",
      //     alt: "Roman Blinds",
      //     p: [
      //       "Unique design that combines the luxury feel of curtains and functionality of blinds. Add warmth and sophistication to your home.",
      //     ],
      //   },
      //   {
      //     image: "blind-3",
      //     alt: "Outdoor Zip Blinds",
      //     p: [
      //       "Transform your outdoor space into an indoor one to enjoy and relax in peace. Zip Blind filters light, reduce glare, heat, and offers privacy. It can also help to keep in air-conditioned cool air and block out 95% rain!",
      //     ],
      //   },
      // ],
      // blind_system: [
      //   {
      //     h1: "Normal System",
      //     p: "It has a 2 string system where 1 string controls the up and down motion of the blinds, locking and unlocking function while the other string for adjusting the angle of the slate to control the amount and angle of the sunlight.",
      //   },
      //   {
      //     h1: "Mono system",
      //     p: "It is a 1 string system that have the function to control the up and down motion, locking and unlocking and also adjust the angle of the slate. This system is less messy compared to the normal system.",
      //   },
      //   {
      //     h1: "Easy Lift",
      //     p: "It is an upgraded version of the normal system with 2 strings whereby you use lesser strength to pull the blind up so it is advisable for customers opting for bigger blinds to get this system.",
      //   },
      // ],

      perfect_fit: [
        {
          image: "perfect-1",
          alt: "Custom Curtains",
          p: "Made-to-measure curtains keep out the strong sunlight of Malaysia and protect your privacy. They add a luxury feel to your window, matching with your personalised style. Discover our stunning gorgeous curtain fabrics",
        },
        {
          order:'md:order-2',
          image: "perfect-2",
          alt: "Custom Blinds",
          p: "Different blinds offer different functions in light control and privacy protection. Select your desired fabrics & colours to match your style. Explore our range of beautiful made-to-measure blinds to transform your unique space.",
        },
      ],
      choose_us: [
        {
          image: "choose-1",
          alt: "Complimentary Onsite Consultation",
        },
        {
          image: "choose-2",
          alt: "Instant Estimate",
        },
        {
          image: "choose-3",
          alt: "Transparent Fair Pricing",
        },
        {
          image: "choose-4",
          alt: "Service Guarantee",
        },
      ],
    };
  },
};
</script>

<style>
@media (min-width: 1024px) {
  .owl-carousel .owl-item img {
    display: block;
    width: 60% !important;
  }
}
@media (min-width: 1280px) {
  .owl-carousel .owl-item img {
    width: 70% !important;
  }
}
</style>
