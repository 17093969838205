<template>
  <div>
    <div class="py-10 bg-gray-100">
      <div class="container md:max-w-xl lg:max-w-2xl md:mx-auto">
        <div class="text-center">
          <p class="text-2xl font-semibold leading-tight tracking-wide text-gray-700 lg:text-3xl">
            Verified Customer Reviews
          </p>
          <p class="pt-4 font-semibold leading-tight lg:text-lg text-gold-1">
            What happy customers say about us on Facebook and Google.
          </p>
        </div>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              // 767: { items: 2, nav: false, dots: true },
              // 1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 mx-auto text-gray-500 fill-current"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
                  />
                </svg>
                <div class="pt-3">
                  <p class="h-48 text-sm text-gray-600 lg:text-base">
                    {{ item.p }}
                  </p>
                </div>
                <div class="pt-4">
                  <p class="text-lg font-semibold">{{ item.client }}</p>
                  <div class="text-gold-1">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="hidden">
          <img src="/images/bg-testimonial.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "Blackout curtains installed are impressive. Very good quality material and design are very pleasant to look at. They work closely to work on my wall layout to design a totally dark rooms, good for sleeping in during the day and weekend ",
          client: "Jacinta Yong",
        }, 
        {
          p: "Very organized and reliable in their impeccable service. The curtains and blinds really add finishing touches to uplift the lovely feel of our new home! Thanks for the great work!",
          client: "Kathy Lew",
        }, 
        {
          p: "Excellent service from An. Great quality products. Good prices and fast delivery/installation. Highly recommended. Would use again.",
          client: "Crystal L",
        }, 
        {
          p: "Their Curtains & Blind was really gorgeous and of super quality. I would highly recommend to anyone who are looking to install curtains & Blinds.",
          client: "Jonathan M.",
        }, 
        {
          p: "Efficient, great communication, fantastic quality of craftsmanship and great customer service.",
          client: "Lim Kok Siong",
        }, 
        {
          p: "Reasonable pricing. The installer did the installation fast and quick. Measurement was fast as well. Items are nice. Salesperson Irene was patient to explain and gave us good advice to save cost.",
          client: "Mohd Hafiz bin Hassan",
        }, 
        {
          p: "installation was quick and staff was experienced and professional. Overall was satisfied with service.",
          client: "Donald Yeh",
        }, 
        {
          p: "Curtains are so beautiful. Installers were fast and professional. The guy who came for measurement was friendly and provide suggestions. Great service!",
          client: "Maziah Suri",
        }, 
      ],
    };
  },
};
</script>
