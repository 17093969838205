<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <TopHeader />

      <!-- banner tagline -->
      <div class="py-44 md:py-48 lg:py-60">
        <div class="container">
          <div class="text-2xl tracking-wide text-center text-white md:text-3xl lg:text-4xl xl:text-5xl">
            <p class="font-semibold leading-tight">
              Premium <br />
              Curtains & Blinds
            </p>
            <p class="pt-6 font-semibold">Customised to Transform</p>
            <h1 class="font-semibold xl:pt-4">
              Your <span class="text-gold-1">Beautiful Home</span>
            </h1>
          </div>
          <div v-if="!hideEnquiry" class="mt-6">
            <p
              class="w-48 py-1 mx-auto font-semibold text-center text-white transition duration-200 transform rounded-md hover:scale-105 hover:bg-gold-1 bg-gold-2"
            >
              <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }">
                Get A Free Quote
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TopHeader,
  },
  // data() {
  //   return {
  //     fixtop: false,
  //   };
  // },
  // methods: {
  //   scrollListener: function (e) {
  //     this.fixtop = window.scrollY > 150;
  //   },
  // },
  // mounted: function () {
  //   window.addEventListener("scroll", this.scrollListener);
  // },
  // beforeDestroy: function () {
  //   window.removeEventListener("scroll", this.scrollListener);
  // },
};
</script>
